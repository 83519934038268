.inputs {
    justify-content: space-around;
    width: 100%;

}

.primaryInputs {
    width: 22em;
}

.additionalData {
    margin-left: 2em;
    width: 18em;
    margin-top: auto;
    margin-bottom: auto;
}

.additionalDataRun {
    margin-left: 2em;
    width: 22em;
    margin-top: auto;
    margin-bottom: auto;
}

#additionalDataRun {
    height: 25em;
}

.template-form {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-left: 3em;
    padding-bottom: 3em;
}

.template-form-run {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-left: 3em;
    margin-top: 3em;
    padding-bottom: 3em;
}


.dotted-line {
    border: none;
    border-top: 2px dotted blue;
    color: #fff;
    background-color: #fff;
    height: 1px;
    width: 100%;
    margin-top: 2em;
    margin-bottom: 1em;
    ;
}

.generatedPrompt {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    min-height: 16em;
    overflow: visible
}

#generateButton {
    height: 3em !important;
    margin-top: auto;
    margin-bottom: auto;
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.selectModels {
    margin-top: 3em;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
}

.template-name {
    width: 80%;
    margin-bottom: 3em;
    margin-left: auto;
    margin-right: auto
}

#saveTemplateBtn {
    margin-top: 2em;
}

.savedTemplateStatus {
    display: flex;
    flex-direction: column;
    margin-top: 1em;
    margin-left: auto;
    margin-right: auto;
    max-width: 60%;
    ;
}

#runGeneratedTemplate {
    margin-top: 2em;
    margin-bottom: 1em;
    ;
}

.margin-right {
    margin-right: 3em;
}

.templateTable {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.templateRun {
    margin: 3em;
}

.templateRunDesc {
    text-align: left;
}

.sorted-models {
    justify-content: space-around;
    padding: 2em;
}

.TemplateVersionTable {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.testTemplate {
    justify-content: space-around;

}

.addDataCallout {
    margin-top: 1.5em;
    display: flex;
    justify-content: center;
}


#disabledButton {
    margin-bottom: 1em;
}

.openAI {
    margin-right: 1em;
}

.TestTemplateResult {
    margin-top: 2em;
}

.resultCards {
    display: flex;
    flex-direction: column;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.resultCards>* {
    margin-top: 1em;
}

.indvResult {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    margin-bottom: 1em;
}

#createPromptTemplate{
    margin-bottom: 2em;
}