ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #F8F8FA;
}

.navbar>ul>li {
  float: left;
}

li a {
  display: block;
  color: #434A4F;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

li a:hover {
  background-color: #B8C9D8;
}