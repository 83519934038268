.authPage{
   margin:auto;
   margin-top:auto;
   margin-bottom: auto;
   max-width: 60%;
}

.errorMsgCard{
   display: flex;
   justify-content: center;
   margin-left: 4em;
   margin-right: 4em;
}